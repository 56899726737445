import React from "react";
// import Nav from "./nav";
import Hero from "./blocks/hero";
// import About from "./blocks/about";
import Contact from "./blocks/contact";

function Home() {
  return (
    <>
      {/* <Nav /> */}
      <Hero />
      {/* <About /> */}
      {/* <Services /> */}
      {/* <Testimony /> */}
      <Contact />
    </>
  );
}

export default Home;
